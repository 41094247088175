import React, { Fragment, useEffect } from "react";
import {
  Router,
  Route,
  Switch
  // Redirect
  // BrowserRouter,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { updateCount, addCount } from "services/countsServices";
import site_img from "./assets/siteImages/msapplication-TileImage.png";
// import { updateCount,addCount } from "/src/services/";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const App = props => {
  const visitorCount = async () => {
    const visit_data = { visit_count: 1 };

    await updateCount(visit_data).then(data => {
      if (data.success) {
        // success(data.message);
      } else {
        // error(data.message);
      }
    });

    await addCount(visit_data).then(data => {
      if (data.success) {
        // success(data.message);
      } else {
        // error(data.message);
      }
    });
  };

  useEffect(() => {
    visitorCount();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Wholesale</title>
        <meta name="robots" content="max-image-preview:large" />
        <meta name="generator" content="WordPress 5.8.4" />
        <meta name="generator" content="WooCommerce 5.6.2" />
        <meta
          name="generator"
          content="Powered by WPBakery Page Builder - drag and drop page builder for WordPress."
        />
        <meta
          name="msapplication-TileImage"
          // content="https://wholesale-magnets.com.au/wp-content/uploads/2022/02/favicon-16x16-1.png"
          content={site_img}
        />
      </Helmet>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <StyleRoot>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {/*Start layout routes */}
                <Router history={history}>
                  <Switch>
                    {layoutRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                          history={history}
                        />
                      );
                    })}
                  </Switch>
                </Router>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                {/*End layout routes */}
              </PersistGate>
            </Provider>
          </StyleRoot>
        </ThemeProvider>
      </IntlProvider>
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
