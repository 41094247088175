import React, { useEffect, useRef } from "react";

import dashboardRoutes from "routes/dashboardRoutes";

import "../assets/css/dashboardlayout.css";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { ProtectedRoute } from "../routes/ProtectedRoute";
import NavigationActions from "redux/navigation/actions";

import HeaderUser from "components/header/HeaderUser";
import SideBarUser from "components/sidebar/SideBarUser";
import FooterUser from "components/footer/FooterUser";
import { useState } from "react";
import galleryHeader from "../assets/siteImages/GalleryHeader.png";

const { scroll } = NavigationActions;
const DashboardLayout = props => {
  const history = useHistory();
  const location = useLocation();
  //eslint-disable-next-line
  const [state, setState] = useState({ scrollTop: 0 });
  const scrollRef = useRef();
  const btnshow = useRef();
  const scrollTo = () => scrollRef.current?.scrollTo(0, 0);

  useEffect(() => {
    if (!scrollRef) {
      scrollTo();
    }
    // eslint-disable-next-line
  }, [scrollRef.current]);

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (scrollRef.current?.scrollTop > window.screen.height / 2) {
      setShowScroll(true);
    } else if (scrollRef.current?.scrollTop <= window.screen.height / 2) {
      setShowScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop, { capture: true }); //true
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (props.step_scroll === "scroll") {
      scrollRef.current.scrollTo(0, 0);
      props.scroll("");
    }
    // eslint-disable-next-line
  }, [props.step_scroll]);

  const onScroll = () => {
    // const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    const scrollTop = scrollRef.current?.scrollTop;
    setState({
      scrollTop: scrollTop
    });
  };

  return (
    <>
      <div
        className="container-fuild full-height"
        onScroll={() => onScroll()}
        ref={scrollRef}
      >
        <HeaderUser />
        {history.location.pathname.includes("/our-gallery") ? (
          <div className="row mb-4">
            <img
              // src="https://wholesale-magnets.com.au/wp-content/uploads/2020/07/rsz_11wholesale_magnets_website-05_2-1.png"
              src={galleryHeader}
              className="myimg"
              alt=""
            />
          </div>
        ) : null}
        <div className="container">
          <div className="row mt-3 content">
            <div className="col-9 mx-auto main-block">
              <div className="row instant-price-main">
                <SideBarUser />
                <div className="col-lg-8 col-md-8 col-sm-7">
                  <Switch>
                    <ProtectedRoute {...props}>
                      {dashboardRoutes.map((prop, key) => {
                        return (
                          <Route
                            exact
                            path={prop.path}
                            component={prop.component}
                            key={key}
                          />
                        );
                      })}
                    </ProtectedRoute>
                  </Switch>
                  {/* {props.children()} */}
                  {/* <Content /> */}
                  {/* <Detail /> */}
                  {/* <Artwork /> */}
                  {/* <Userdetail /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={btnshow}></div>
        <FooterUser />
        {showScroll ? (
          <div>
            <span
              onClick={() => {
                scrollTo();
              }}
              className="add-fix-top active"
              // className={show ? "add-fix-top active" : "add-fix-top"}
            >
              <i className="fas fa-angle-up"></i>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    },
    step_scroll: state.navigation.step_scroll
  };
};

export default connect(mapStateToProps, {
  scroll
})(DashboardLayout);
