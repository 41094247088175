const productimageAction = {
  IMAGE: "IMAGE",

  image: data => {
    console.log("image", data);
    return {
      type: productimageAction.IMAGE,
      payload: data
    };
  }
};

export default productimageAction;
