import React, { useEffect, useState } from "react";
import axios from "axios";
import { getProductQuantitiesTPA } from "services/thirdpartyapiServices";

export default function Detail(prop) {
  const {
    step,
    setStep,
    alldata,
    setAlldata,
    pdata,
    IPData,
    setIPData
  } = prop.data;

  const [ans, setAns] = useState(pdata?.pquantity?.split(","));
  const getSKUData = async sku => {
    await getProductQuantitiesTPA({ sku: sku }).then(res => {
      console.log("res tata", res.data);
      if (res.data) {
        if (res.data.length > 0) {
          setAns(
            res?.data[0] === "Select Quantity"
              ? res?.data
              : ["Select Quantity", ...res.data]
          );
        }
      }
    });
    // await axios
    //   .get(
    //     `http://vmh20064.hosting24.com.au/website/print_sms_server_quantity.asp?product=.${sku}`
    //   )
    //   .then(res => {
    //     if (res.status) {
    //       setAns(res.quantities.split(","));
    //     }
    //   });
  };

  useEffect(() => {
    console.log("pdata", pdata);
    if (pdata.SKU) {
      getSKUData(pdata.SKU);
    }
    // eslint-disable-next-line
  }, [pdata?.SKU]);

  return (
    <>
      <div className="row wanting">
        <div className="col-sm-12">
          <div className="isCatNameTag">
            <h4 className="p-title">{pdata.category}</h4>
          </div>
          <h5>
            <span className="mr-2">{pdata.pname}</span>
            {pdata.size}
          </h5>
          <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
          <div className="col-12 col-md-9 my-2 px-0">
            <h4 className="main-title">How many are you wanting ?</h4>
            <select
              // style={{width:"100%"}}
              className="want_width"
              value={alldata.quantity}
              onChange={e => {
                console.log("last log");
                setAlldata({
                  ...alldata,
                  quantity: e.target.value
                });
              }}
            >
              {ans.map((val, ind) => {
                return (
                  <option key={ind} value={val}>
                    {val}
                  </option>
                );
              })}
              {console.log("dsssdsdsdssddsajvakdmhdha")}
            </select>
          </div>
          <div className="btn-align mb-3">
            <button
              type="button"
              className="btn btn_base_style my-2 mx-1"
              onClick={() => {
                alldata?.category === "ENVELOPES"
                  ? setStep(step - 0.5)
                  : alldata?.category === "FLYERS"
                  ? setStep(step - 0.4)
                  : setStep(step - 1);

                setIPData({
                  ...IPData,
                  completedStep: IPData.completedStep - 1
                });
              }}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn_base_style my-2"
              onClick={() => {
                // alldata?.category === "SHOPPING LIST TO DO LISTS"
                // ? setStep(step - 0.5)
                // eslint-disable-next-line
                if (alldata.quantity == undefined) {
                  setAlldata({
                    ...alldata,
                    quantity: ans[0]
                  });
                }
                setStep(step + 1);
                setIPData({
                  ...IPData,
                  stepName: "Quantity Selected",
                  completedStep: IPData.completedStep + 1
                });
              }}
            >
              Next
            </button>
          </div>
          <div className="">
            <span className="cateogoryDescp">
              <div
                dangerouslySetInnerHTML={{
                  __html: pdata.description
                }}
                className="notes-text"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
