import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ContactUs from "./contactUs";

export default function contact() {
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined
        }}
      >
        <ContactUs />
      </GoogleReCaptchaProvider>
    </>
  );
}
