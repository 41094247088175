import React, { useEffect, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { getFAQs, getFAQTypes } from "services/faqServices";
import faqsmsgHere from "../../../assets/siteImages/faqsmsgHere.png";
// import { Value } from "sass";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function Faqs(props, eventKey, callback) {
  // const { token } = props;
  const [FAQList, setFAQsList] = useState([]);
  const [FAQtypes, setFAQtypes] = useState([]);
  const [step, setstep] = useState(1);
  const [navStep, setNavStep] = useState(1);
  const [size, setSize] = useState(true);

  const getFAQsList = async () => {
    fetching();
    await getFAQs().then(data => {
      if (data.success) {
        setFAQsList(data.data);
        success();
        // toggleRefresh(false);
      } else {
        error(data.message);
      }
    });
    await getFAQTypes().then(data => {
      if (data.success) {
        setFAQtypes(data.data);
        success();
        // toggleRefresh(false);
      } else {
        error(data.message);
      }
    });
    // eslint-disable-next-line
  };

  useEffect(() => {
    getFAQsList();
  }, []);

  useEffect(() => {
    // window.addEventListener("resize", function() {
    if (window.screen.width > 768) {
      setSize(true);
    } else {
      setSize(false);
    }
    // });
  }, [size]);
  return (
    <>
      <Helmet>
        <title>FAQ – Wholesale</title>
      </Helmet>
      <div className="apus-breadscrumb1">
        <img
          // src="http://wholesale-magnets.com.au/wp-content/uploads/2020/06/rsz_11wholesale_magnets_website-05_2.png"
          src={faqsmsgHere}
          alt=""
        />
      </div>
      <div className="container mb-3 faq_full_content">
        <div className="row ">
          <div className="col-12">
            <div
              style={{ fontSize: "30px", color: "#e72029", margin: "30px 0" }}
              className="vc_custom_heading sitHds vc_custom_1593660413644 faq_main_title"
            >
              Frequently Asked Questions
            </div>
          </div>
        </div>
        <div className="row">
          {/* ul tag */}

          <div className="col-md-5 col-lg-4 col-xl-3 mb-5">
            <div className="wpb_wrapper">
              <div className="vc_tta-container" data-vc-action="collapse">
                <div className="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classNameic vc_tta-shape-rounded vc_tta-spacing-1 vc_tta-tabs-position-left vc_tta-controls-align-left ">
                  <div className="vc_tta-tabs-container">
                    <ul
                      className="vc_tta-tabs-list"
                      style={{ cursor: "pointer" }}
                    >
                      {FAQtypes.map((val, ind) => (
                        <li
                          key={ind}
                          className={
                            step === val.id
                              ? "vc_tta-tab vc_active"
                              : "vc_tta-tab"
                          }
                          data-vc-tab=""
                          onClick={() => {
                            setstep(val.id);
                          }}
                          title={val?.faq_name}
                        >
                          <span className="vc_tta-title-text content_elipsis">
                            {val?.faq_name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* description */}

          {size ? (
            <div className="col-md-7 col-lg-8 col-xl-9 mb-5">
              {FAQtypes.map((value, index) => {
                return step === value.id ? (
                  <div
                    className="faq-accordion "
                    id="faq-accordion-1"
                    data-accordion-group=""
                    data-conf='{"single_open":"true","transition_speed":300}'
                  >
                    <Accordion flush>
                      {FAQList.map((val, ind) => {
                        return val.faq_id === value.id ? (
                          <div className="faq-main open">
                            <Accordion.Item eventKey={ind}>
                              <div className="faq_nav text-uppercase">
                                <span className="faq_nav_title">
                                  {val.faq_name}
                                </span>
                              </div>
                              <Accordion.Header>
                                <div className="faq-title">
                                  <h4>{val.question}</h4>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="faq-content">
                                  <p
                                    style={{ padding: "2px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: val.answer
                                    }}
                                  />
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    </Accordion>
                  </div>
                ) : (
                  <></>
                );
              })}
            </div>
          ) : (
            <div className="container-fluid">
              <div className="row w-100">
                <div className="nav_link_space col-12 mx-3 px-0">
                  <div
                    // calssName="faq-accordion "
                    id="faq-accordion-1"
                    data-accordion-group=""
                    data-conf='{"single_open":"true","transition_speed":300}'
                  >
                    {FAQtypes.map((value, index) => {
                      return (
                        <>
                          <div
                            className="faq_nav"
                            onClick={() => setNavStep(value.id)}
                          >
                            <span className="faq_nav_title">
                              {value.faq_name}
                            </span>
                          </div>
                          <Accordion
                            flush
                            className={
                              navStep === value.id
                                ? "nav_active accord_item_border"
                                : "nav_inActive"
                            }
                          >
                            {FAQList.map((val, ind) =>
                              navStep === val.faq_id ? (
                                <div className="faq-main open">
                                  <Accordion.Item eventKey={ind}>
                                    <Accordion.Header>
                                      <div className="faq-title">
                                        <h4>{val.question}</h4>
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="faq-content">
                                        <p
                                          style={{ padding: "2px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: val.answer
                                          }}
                                        />
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </div>
                              ) : (
                                <></>
                              )
                            )}
                          </Accordion>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Faqs);
