import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Helmet } from "react-helmet";
import { getNews } from "services/newsServices";
import NewsHeader from "../../../assets/siteImages/newsHeader.png";
import DisplayEditorContent from "./DisplayEditorContent";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function News(props) {
  const { token, success, error, history } = props;

  const { slug } = useParams();
  const [news, setNews] = useState([]);
  console.log("news", news);
  const [newsdata, setNewsData] = useState([]);

  const [step, setStep] = useState(1);

  const getData = async () => {
    await getNews(token).then(data => {
      if (data.success) {
        setNews(data.data);
        success();
      } else {
        error();
      }
    });
  };

  useEffect(() => {
    getData();
    setStep(1);
  }, [slug]);
  return (
    <>
      <Helmet>
        <title>News – Wholesale</title>
        <meta name="robots" content="max-image-preview:large" />
      </Helmet>

      <>
        <div className="apus-breadscrumb">
          <img
            // src="https://wholesale-magnets.com.au/wp-content/uploads/2020/07/rsz_11wholesale_magnets_website-05_2.png"
            src={NewsHeader}
            alt=""
          />
        </div>
        <div>
          <div className="row">
            {news.map((val, key) => {
              let { news_description } = val;
              news_description = JSON.parse(news_description);

              val = { ...val, news_description: news_description };
              return (
                <div className="col-md-6 mb-4" key={key}>
                  <div className="mb-3">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.news_image}`}
                      alt="blog-image"
                      className="img-fluid cursor-pointer img-contain"
                      onClick={() =>
                        history.push(
                          `/news/${encodeURIComponent(
                            val.news
                              .trim()
                              .split(" ")
                              .join("_")
                          )}`
                        )
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <h5
                      className="article-item__title cursor-pointer"
                      onClick={() =>
                        history.push(
                          `/news/${encodeURIComponent(
                            val.news
                              .trim()
                              .split(" ")
                              .join("_")
                          )}`
                        )
                      }
                    >
                      {val.news}
                    </h5>
                  </div>
                  <div
                    className="mb-3 cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/news/${encodeURIComponent(
                          val.news
                            .trim()
                            .split(" ")
                            .join("_")
                        )}`
                      )
                    }
                  >
                    <DisplayEditorContent
                      isshortNews={true}
                      editorData={[
                        val?.news_description?.blocks?.find(
                          block => block.type === "paragraph"
                        )
                          ? val?.news_description?.blocks?.find(
                              block => block.type === "paragraph"
                            )
                          : ""
                      ]}
                    ></DisplayEditorContent>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(News);
